import React from 'react'
import { graphql } from 'gatsby'
import Image from 'gatsby-image'

import SEO from '../components/SEO'
import FullWidthWrapper from '../components/FullWidthWrapper'
import Overlay from '../components/Overlay'
import FlexBox from '../components/FlexBox'
import WordArt from '../components/WordArt'

const NotFoundPage = ({ data }) => (
  <React.Fragment>
    <SEO title="Page Not Found" />
    <FullWidthWrapper height="100vh">
      <Image
        fluid={data.heroImage.childImageSharp.fluid}
        alt=""
        css={`
          height: 100%;
        `}
      />
      <Overlay alpha={0.2} />
      <FlexBox
        flexDirection="column"
        alignItems="center"
        css={`
          position: absolute;
          top: 6vmax;
        `}
      >
        <Heading />
      </FlexBox>
    </FullWidthWrapper>
  </React.Fragment>
)

const Heading = () => (
  <WordArt.Wrapper
    css={`
      max-width: 80vw;
      text-align: center;
    `}
  >
    <WordArt.Line color="#fff" fontSize="0.6em">
      You've reached the edge of space.
    </WordArt.Line>
    <WordArt.Line color="#888" fontSize="0.4em" lineHeight="2">
      (Page not found)
    </WordArt.Line>
  </WordArt.Wrapper>
)

export const query = graphql`
  query {
    heroImage: file(relativePath: { eq: "spaceman.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 5000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default NotFoundPage
